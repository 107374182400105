let host = location.host;
let TestSite = ['aemtest.com', 'store-rev.modd.com'];


$(function () {
    let $body = $('body');
    let bodyId = $body.attr('id');

    if (bodyId === 'MyPage') {
        MyPage();
    } else if (bodyId === 'fcJoinEntry') {
        fcJoinEntry()
    } else if (bodyId === 'fc_help') {
        fc_help()
    } else if (bodyId === 'Login') {
        Login()
    } else if (bodyId === 'PassEdit') {
        PassEdit()
    }
});

// Register
function pageRegister() {
    // $('.tblbody_email .table_control').prepend('<div class="mb-20"><a href="" target="_blank">【利用規約】</a>にご同意の上、メールアドレスを入力してください。</div>');
}

// RegisterStep1
function RegisterStep1() {
    // $('.table_label.col_must').each(function(){
    //     let $title = $(this);

    //     if($title.text().indexOf('郵便番号') > -1) {
    //         $title.append('<div class="c-red">※ハイフンなしの半角数字で入力してください。</div>');
    //     }
    // });
}

// MyPage
function MyPage() {
    // アコーディオンを開いておく
    $('.table_collapse_link').removeClass('collapsed').attr('aria-expanded', 'true');
    $('.glyphicon').removeClass('glyphicon-chevron-left').addClass('glyphicon-chevron-down');
    $('.table_collapse').addClass('in');
}

function fcJoinEntry() {
    // 自動継続更新のチェックボクスをonにする
    $('#card_fc_auto').prop('checked', true);
    $('.t_message_center').html('<a class="" href="/v2/help/fc_kiyaku.aspx?ccode=UCDM" target="_blank">会員規約</a>、<a class="" href="https://www.ponycanyon.co.jp/privacy_policy" target="_blank">プライバシーポリシー</a>に同意の上、次へ進む')
}

function fc_help() {
    $('.t_title').text('よくある質問');
}

// login
function Login() {
    $('.login_table .login_body').append('<div class="al-c mt-10"><a class="c-red" href="https://maaya-fc.jp/special/2021renewal_info/">【重要】リニューアル後<br>初めてログインされる方はこちら</a></div>');
}

// PassEdit
function PassEdit() {
    $('#panel_change_pass .tblbody_new_pass .table_control').append('<div class="mt-10 small c-red">※英数字混合8〜32文字以内で設定してください。<br />※大文字と小文字は区別されます。</div>');
}

$(function() {
    $('.js-accordion').aemAccordion({
        speed: 200, // スピード
        easing: 'linear', // イージング
    });
});